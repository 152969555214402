import { useContext, useState } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import axios from 'axios'
import { GetStaticProps } from 'next'
import { getFlags } from 'flags/server'
import { AppFlags } from 'flags/config'

import seo from '@/translations/seo'
import t from '@/translations/v2/startPage'

import Hero from '@/components/v2/hero/Hero'

import BookingStepsInfo from '@/components/v2/prebuilt/BookingStepsInfo'
import GroupPaddleInfo from '@/components/v2/prebuilt/GroupPaddleInfo'
import Newsletter from 'components/v2/forms/Newsletter/Newsletter'
import Safety from '@/components/v2/animation/Safety'
import ShopInfo from 'components/v2/prebuilt/ShopKayaks'
import VideoHowToInfo from '@/components/v2/prebuilt/VideoHowToInfo'

import Wrapper from '@layout/Wrapper'
import Container from '@layout/Container'

import H3 from '@library/Texts/H3'
import P from '@library/Texts/Paragraph'
import Column from '@layout/Column/Column'
import ColumnContainer from '@layout/Column/Container/Container'
import ImageRoundCorners from '@library/Media/Image/RoundCorner/RoundCorner'
import FindStationsNear from '@/components/v2/prebuilt/FindStationsNear'

import AppContext from 'context/appContext'

export default function Home({ countries, locale, suppliers, flags }) {
  const { location } = useContext(AppContext)
  const [suppliersList, setSuppliersList] = useState(
    suppliers.sort((a, b) => a.name.localeCompare(b.name, 'sv'))
  )

  const [search, setSearch] = useState({
    country: countries.find((c) => c.shortcode === 'se'),
    searchText: '',
    toggle: 'list',
    userLocation: {
      lat: false,
      lng: false,
    },
  })

  return (
    <>
      <Head>
        <title>{seo.homeTitle[locale]}</title>
        <meta name="description" content={seo.homeDescription[locale]} />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel={locale === 'en' ? 'canonical' : 'alternate'}
          href={
            locale === 'en'
              ? 'https://www.kayakomat.com'
              : `https://www.kayakomat.com/${locale}`
          }
        ></link>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>
      <Wrapper gap={120}>
        <Hero
          countries={countries}
          suppliersList={suppliersList}
          locale={locale}
          ipLocation={location}
        />
        {flags.secondHandSales && <ShopInfo locale={locale} />}
        <BookingStepsInfo locale={locale} />
        <FindStationsNear locale={locale} suppliers={suppliersList} />
        <VideoHowToInfo locale={locale} />
        <Container>
          <ColumnContainer gap={50}>
            <Column width={50} vAlign="center">
              <H3>{t.titleSafety[locale]}</H3>
              <P>{t.textSafety[locale]}</P>
            </Column>
            <Column width={50}>
              <Safety locale={locale} />
            </Column>
          </ColumnContainer>
        </Container>
        <GroupPaddleInfo locale={locale} />
        <Container>
          <ColumnContainer gap={50}>
            <Column width={50}>
              <ImageRoundCorners src="/images/KAYAKOMAT_Homepage_Franchise.jpg" alt="Happy KAYAKOMAT operator helping a customer return their rental kayak to the automatic kayak rental station." />
            </Column>
            <Column width={50} vAlign="center">
              <H3>{t.titleStart[locale]}</H3>
              <P>{t.textStart[locale]}</P>
              <Link href={'/start-kayakomat-franchise'} passHref>
                <P>{t.learnMoreLink[locale]}</P>
              </Link>
            </Column>
          </ColumnContainer>
        </Container>
        <Newsletter locale={locale} location={location} countries={countries} />
      </Wrapper>
    </>
  )
}

type StaticProps = { flags: AppFlags | null }
export const getStaticProps: GetStaticProps<StaticProps> = async (context) => {
  const initialFlags = await getFlags({ context })

  try {
    const suppliers = await axios.get(
      `${process.env.API_URL}/web/suppliers?responseData=list`
    )

    const countries = await axios.get(`${process.env.API_URL}/web/countries`)

    return {
      props: {
        countries: countries.data,
        suppliers: suppliers.data
          .filter((s) => s.active)
          .map((s) => {
            return {
              _id: s._id,
              active: s.active,
              inactiveBooking: s.inactiveBooking || false,
              address: s.address,
              country: s.country || '',
              countryId: {
                shortcode: s.countryId.shortcode,
                name: s.countryId.name,
              },
              lat: s.lat,
              lng: s.lng,
              name: s.name,
              postCode: s.postCode,
              city: s.city,
              tags: s.tags || [],
              openDates: s.openDates || [],
              openingDate: s.openingDate || null,
              openDateResolution: s.openDateResolution || 'month',
            }
          }),
        page: 'locations',
        locale: context.locale,
        flags: initialFlags.flags,
      },
      revalidate: 60,
    }
  } catch (err) {
    console.log(err)
  }
}
